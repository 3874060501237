import React from "react";
import { Paper } from "@material-ui/core";

import Layout from "../components/Layout";
class Privacy extends React.Component {
	render() {
		return (
			<Layout showBrands={false} bg="#212322">
				<div className={"privacy-policy-root"}>
					<Paper className={"privacy-policy-paper"}>
						<p class="p1">
							<span class="s1">
								<strong>1. General Description</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>1.1 Introduction</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; These User Terms and Conditions (this &ldquo;Agreement&rdquo;), is a legal
								agreement between you and Peng Kitchens AB (559301-5810), a Swedish company
								(&ldquo;Bite&rdquo;), which guides your use of and access to the Website (as defined
								below). &nbsp; If you don&rsquo;t want to be bound by this Agreement, please do not
								access or use the Website. Bite may terminate, without notice, your access to the
								Website for failure to comply with this Agreement. &nbsp; By creating an account or
								ordering on the Website, you accept this Agreement and agree to be bound by each of its
								terms. You also represent and warrant to Bite that: &nbsp; You are at least sixteen
								years of age and have the authority to enter into this Agreement (either on your own
								behalf or by having a parent or legal guardian agreeing on your behalf) &nbsp; This
								Agreement is binding and enforceable against you &nbsp; To the extent an individual is
								accepting this Agreement on behalf of an entity, such individual has the right and
								authority to agree to all of the terms set forth herein on behalf of such entity &nbsp;
								You have read and understand Bite&rsquo;s Privacy Policy, the terms of which are posted
								at the Website and incorporated herein by reference (the &ldquo;Privacy Policy&rdquo;).
								&nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>1.2 What Bite offers</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; The Bite service is a sales channel via which our restaurant offer take away food
								items to users.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>2. Purpose</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; The purpose of this Agreement is to explain the terms and conditions under which
								Bite will let you use the Application through your mobile device and/or use the Website
								(collectively, the &ldquo;Purpose&ldquo;).
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>3. The Bite Service</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.1 Order</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; &nbsp; How Bite may store and use of the personal information provided by you
								when order and/or using the the Website is set out in the Privacy Policy. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.3 Information about the food items</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Through the Application you are presented with a description of the food item so
								that you as a customer will get as good information about ingredients, price etc.
							</span>
						</p>

						<p class="p2">
							<span class="s1">
								<strong>3.4 Order and order confirmation</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; As a Bite user, you can place an order for a food item through the Website. After
								placing the order you will get an order confirmation via email. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.5 Payment of the order</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; You pay for ordered food items through the Website when placing an order. If an
								ordered hasn&rsquo;t been paid for through the Website, it will not be considered a
								completed order. &nbsp; Bite may use a payment service provided by a third-party to
								handle the payment and will automatically charge your chosen account at the time of your
								order. You have no right to cancel or change a placed order when using the Application
								or the Website. The restriction of the right of cancel a made order is based on the
								nature of food which may spoil or age rapidly. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.6 Pick up of order, redemption and delivery</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Upon arrival, the order will be placed on the counter as soon as it is ready.
								&nbsp; When using the Application or Website you shall always pick up the food item(s)
								ordered from the applicable location in a timely manner from when it is approximated to
								be ready when ordered. If you do not collect your order in a timely manner, Bite has the
								right to do as they see fit with the ordered food item. You are not entitled to receive
								a refund for ordered food items that have not been collected on time. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.7 Home delivery</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; In certain cases, delivery can be offered for your order. If delivery is included
								in an order, the order will be delivered to the address specified by you in the
								Application or on the Website while completing the order, or supplemented by email to
								Bite after the order has been completed. &nbsp; All deliveries are handled either by
								Bite or by Bite&rsquo;s hired delivery firms. &nbsp; Delivery and delivery time may vary
								depending on what you order and from where. Please refer to specific delivery
								information given in the Application or on the Website. &nbsp; You undertake to be
								available at the delivery address and reachable at the phone number specified by you
								while completing the order from the time of the order until delivery has been made. If
								you&rsquo;re not available at the delivery address within five (5) minutes from delivery
								your order will be left outside your door, given that no gate or other door is
								prohibiting this. In case your order can&#39;t be left outside your door and you
								don&rsquo;t reply to Bite after two (2) attempts to contact you, Bite or Bite&rsquo;s
								hired delivery firm has the right to cancel your order without any refund.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.8 Fees</strong>
							</span>
						</p>

						<p class="p2">
							<span class="s1">
								<strong>3.9 Reclamations and service disruptions</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								Bite prepares all food items offered through the service in compliance with the
								legislation concerning the handling, storing and transportation of food and in
								accordance with the established principles and practices of the industry. &nbsp; If you
								detect a technical error or other error in the Bite service, you can report the problem
								by contacting us. &nbsp; If there is anything wrong with your food upon pickup
								immidietly contact us through email. We will then go through the message and try to find
								a solution. The solution is fairly decided by Bite on a case-by-case basis.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.10 Customer support</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite offers support via email. Please refer to the Website for up to date
								information on the correct mail-adress.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.11 Advertisements</strong>
							</span>
						</p>

						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>4. Conditions of Use</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>4.1 Certain responsibilities</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; It is your responsibility to make sure that all information provided by you to
								Bite is current, accurate, and complete, and that you will maintain the accuracy and
								completeness of this information going forward. It is also your responsibility to
								protect your Account information as it should be kept strictly confidential. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>4.2 Certain Restrictions</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite does its best to keep the Application and the Website safe and secure but
								need your help to do it by not violating or attempting to violate the security of the
								Application or the Website. &nbsp; Except as expressly permitted under other parts of
								this Agreement, you may not modify, reproduce, duplicate, copy, publish or create
								derivative works of: &nbsp;
							</span>
						</p>
						<ul class="ul1">
							<li class="li4">
								<span class="s1">
									any portion of the Website or the Application, any content, photographs,
									descriptions, software, image or other information or any data taken therefrom
									(collectively, &ldquo;Content&rdquo;). &nbsp;
								</span>
							</li>
							<li class="li4">
								<span class="s1">
									any information or materials retrieved from either of them, which includes graphics
									and logos, presentations, in whole or in part. &nbsp; Furthermore, you may not use
									the Website or Application for any purpose that: &nbsp;
								</span>
							</li>
							<li class="li4">
								<span class="s1">
									invades any person&rsquo;s or entity&rsquo;s privacy or other rights; &nbsp;
								</span>
							</li>
							<li class="li4">
								<span class="s1">
									misidentifies you or impersonates any person or entity, including, without
									limitation, any employee or representative of Bite; or &nbsp;
								</span>
							</li>
							<li class="li4">
								<span class="s1">
									could otherwise reasonably be deemed or viewed to be unethical, illegal or
									offensive. &nbsp;
								</span>
							</li>
						</ul>
						<p class="p2">
							<span class="s1">
								<strong>4.3 Bite's rights</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite has the right to at any time suspend a user or terminate the user&rsquo;s
								account and access to the Application and Website if the user violates this Agreement or
								uses the Website in a way that is harmful to Bite or any third party.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>5. Content</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>

						<p class="p2">
							<span class="s1">
								<strong>5.1 Information accuracy</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								Bite cannot guarantee that the Application and the Website, including the Content, will
								be uninterrupted or error free. Bite cannot be held responsible for if the Website the
								Website is down or if any Content, information or functionality does not work as
								expected. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>5.2 Intellectual property</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; For purpose of clarity between you and Bite, you are the sole owner of all
								information and content entered into the Application or the Website or otherwise posted
								by you. Bite is the sole owner of the name &ldquo;Bite&rdquo; as well as the Website,
								the Application, and all source code, software, content and other intellectual property
								related to it or included in it. &nbsp; All suggestions and recommendations from you to
								Bite regarding the Application or the Website are, upon submission to Bite, owned by
								Bite. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>5.3 Copyright infringement</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite respects the intellectual property of others, and asks you to do the same.
								You may not use the Website, Application, the Content or any other materials from the
								Website or Application in any manner that may infringe upon any copyright or other
								intellectual property right of Bite or any third party.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>6. Term and Termination of Account</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>6.1 Term</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; This Agreement shall continue in full force until either: &nbsp; (i) You
								terminate your Account, or &nbsp; (ii) Bite terminates your access to the Application or
								non-public portions of the Website, with or without notice (the &ldquo;Term&rdquo;).
								&nbsp; When this Agreement is terminated, you no longer have access to the Application
								or any other non-public portions of the Website. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>6.2 Termination of account</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; For purposes of clarity, you are entitled to terminate your Bite account at any
								time. Choose &ldquo;Delete account&rdquo; or any corresponding function in the Website
								the Website, or contact Bite. Bite will then delete or anonymise any personal
								information that can be attributed to you, except for certain information that Bite by
								law may have to save and archive.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>7. Other</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>7.1 Force Majeure</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite shall not be liable to you for failure or delay in performing any
								obligations hereunder if such failure or delay is due to circumstances beyond its
								reasonable control. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>7.2 Changes or updates of the agreement</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite may change or update this Agreement from time to time, and any such change
								or update will be set in effect when posting the updated Agreement on the Website or
								within the Application. &nbsp; When we make major changes to the Agreement, we will
								provide you with prior notice as appropriate under the circumstances, e.g., by
								displaying a notice within the Application, the Website or by sending you an email. If
								you do not agree to the changed Agreement, you have the right to terminate your Account
								before the changed Agreement come into force. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>7.3 Termination of service</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite reserves the right to modify, suspend, or discontinue the Application and
								the Website without any notice at any time and without any liability to you. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>7.4 Miscellaneous</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; All disputes arising hereunder or in connection with this Agreement, the Website
								and/or the Application shall be resolved by the laws of Sweden and the District Court of
								Stockholm (sw. Stockholms tingsr&auml;tt), Sweden. &nbsp; Bite has the right to transfer
								its rights and obligations under this Agreement. Furthermore, Bite has the right to hire
								subcontractors for the fulfillment of its obligations. In such cases is Bite fully
								responsible for the work performed by the subcontractors. &nbsp; If any part of this
								Agreement is held to be unenforceable or invalid for any reason, said part will be
								changed and interpreted to best accomplish its original intent and objectives. Any
								remaining parts will continue in full force.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>
									Thanks for taking the time to learn about our Terms of Service for Users.
								</strong>
							</span>
						</p>
					</Paper>
				</div>
			</Layout>
		);
	}
}

export default Privacy;
